<script setup lang="ts">
import { onBeforeMount, ref, Ref, computed } from "vue";
import useUsers from "@/hooks/useUsers";
import { useRoute } from "vue-router/auto";
import { useUserStore } from "@/stores/user";
import useSupportTickets from "@/hooks/useSupportTickets";
import WarningDialog from "@/components/common/WarningDialog.vue";

const { fetchUser, updatePermissions, getUserPermissionDefinitions, userPermissionsDefinitions } = useUsers();
const { supportTicketRoles } = useSupportTickets();
const route = useRoute();
const userStore = useUserStore();
const permissionsData: Ref<UserPermissions | null> = ref(null);
const dialog = ref(false);

const warningMessage = ref({ message: "", permission: "", color:"yellow" });
const WARNING_TITLE = "Are you sure, you want to enable this permission?";

let currentUser;

const props = defineProps({
  permissions: { type: Object, default: null },
  editMode: { type: Boolean, default: true },
  enableWarnings: { type: Boolean, default: true },
});

onBeforeMount(async () => {

  if (route.params.id) {
    currentUser = await fetchUser(route.params.id);
    await getUserPermissionDefinitions(route.params.id);

    console.log( userPermissionsDefinitions.value, currentUser)
  }

  permissionsData.value = props.permissions || currentUser.permissions;

  supportTicketRoles.value = userStore.hasSupportTicketRole("superadmin")
    ? ["user", "tenant_admin", "superadmin"]
    : userStore.hasSupportTicketRole("tenant_admin")
      ? ["user", "tenant_admin"]
      : ["user"];
});

const handleUpdatePermissions = async () => {
  await updatePermissions(route.params.id, permissionsData.value);
  permissionsData.value = (await fetchUser(route.params.id)).permissions;
};

const handleDialogActions = (agreed: boolean, message: string) => {
  if (!agreed && permissionsData.value[message.permission]) {
    permissionsData.value[message.permission] = false;
  }
  dialog.value = false;
};

const showWarningDialog = (permissions: object, key: string) => {
  if (props.enableWarnings && permissionsData.value[key]) {
    if(permissions?.show_warning) {
      warningMessage.value.message =  permissions?.permission_warning;
      warningMessage.value.permission =  key;
      dialog.value = true;
    }
  }
};

const permissionGroups = computed(() => {
  return [...userPermissionsDefinitions?.value].sort((a, b) => a?.category?.localeCompare(b?.category));
});
</script>

<template>
  <v-card
    flat
    class="mx-4 mt-8"
    v-if="
      (userStore.hasPermissionTo('update_user_permissions') ||
        userStore.user?.id == route.params.id) &&
      permissionsData
    "
  >
    <v-card-title>User Permissions</v-card-title>
    <v-card-subtitle>
      These permissions control various levels and access points within the application.
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            :disabled="!userStore.hasPermissionTo('update_user_permissions')"
            v-model="permissionsData.support_ticket_role"
            :items="supportTicketRoles"
            label="Support Ticket Role"
            name="support_ticket_role"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <template v-for="group in permissionGroups" :key="group.category">
      <div class="text-h7 mx-8 font-weight-medium">{{ group.category }}</div>
      <v-card-text class="mx-4">
        <v-row>
          <v-col v-for="perm in group.permissions" :key="perm.permission_column" cols="6" md="3">
            <v-switch
              :label="perm.permission_label"
              color="success"
              v-model="permissionsData[perm.permission_column]"
              @change="showWarningDialog(perm, perm.permission_column)"
              @click="dialog = false"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </template>

    <v-card-actions v-if="props.editMode" class="flex justify-end">
      <v-btn color="primary" variant="flat" @click="handleUpdatePermissions"
        >Update Permissions</v-btn
      >
    </v-card-actions>
  </v-card>

  <WarningDialog v-if="dialog" :callback="handleDialogActions" :msg="warningMessage" :title="WARNING_TITLE" />
</template>
